import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import logo from '../../images/logos/logo.webp'
import MobileMenu from './MobileMenu'
import DeskMenu from './DeskMenu'
import { useLocation } from '@reach/router'

export default function Header() {
  const isRoot = useLocation().pathname === '/'
  const [isScrolled, setIsScrolled] = useState(false)

  const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop
    setIsScrolled(scrollTop >= 300)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isRoot])
  return (
    <header
      className={`
       fixed lg:absolute w-full max-w-[1600px] z-50 ${ isRoot && !isScrolled ? 'bg-transparent' : 'bg-white border-b'}
      `}
    >
      <nav
        className='flex justify-between items-center py-4 px-2 sm:py-6 sm:px-4 lg:gap-12 xl:px-8 xl:py-12 2xl:px-20'
      >
        <Link to='/'>
          <img
            className='w-32 mx-auto sm:w-40 xl:w-56 2xl:w-72'
            loading='lazy'
            alt='Main'
            src={logo}
          />
        </Link>
        <MobileMenu
          isRoot={isRoot}
          isScrolled={isScrolled}
        />
        <DeskMenu
          isRoot={isRoot}
        />
      </nav>
    </header>
  )
}