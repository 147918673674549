import * as React from 'react'
import { GatsbyBrowser } from 'gatsby'
import { Layout } from './src/components/layouts'
import './src/styles/global.css'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return (
    <Layout>
      {element}
    </Layout>
  )
}