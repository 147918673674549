import React, { useState } from 'react'
import { IoBagOutline, IoMenu } from 'react-icons/io5'
import { RxCross2 } from 'react-icons/rx'
import { Link } from 'gatsby'
import { CiFacebook, CiInstagram } from 'react-icons/ci'

export default function MobileMenu({ isRoot, isScrolled }: { isRoot: boolean, isScrolled: boolean }) {
  const [isOpen, setIsOpen] = useState<boolean | null>(null)
  return (
    <>
      <div
        className={`
        right-0 top-0 absolute h-screen w-72 bg-henkotsu z-50
        lg:hidden ${isOpen === true ? 'menu-enter'
            : isOpen === false ? 'menu-exit'
              : 'hidden'
          }`}
      >
        <RxCross2
          onClick={() => setIsOpen(false)}
          className='ml-auto text-3xl m-4 cursor-pointer'
        />
        <ul
          className='mt-20 ml-16 flex flex-col gap-6 text-lg md:mt-16'
        >
          <li><Link onClick={() => setIsOpen(false)} to='/'>ホーム</Link></li>
          <li><Link onClick={() => setIsOpen(false)} to='/about'>へんこつについて</Link></li>
          <li><Link onClick={() => setIsOpen(false)} to='/menu'>メニュー</Link></li>
          <li><Link onClick={() => setIsOpen(false)} to='/branches'>店舗一覧</Link></li>
          {/* <li><Link onClick={()=>setIsOpen(false)} to='/news'>お知らせ</Link></li> */}
          <li><Link onClick={() => setIsOpen(false)} to='/careers'>採用情報</Link></li>
          <li><Link onClick={() => setIsOpen(false)} to='/contact'>お問い合せ</Link></li>
        </ul>
        <button
          type='button'
          className='ml-12 mt-16 w-48 py-2 flex items-center justify-center gap-2 text-primary-500 border rounded border-primary-500 md:mt-16 invisible'
        >
          テイクアウト <IoBagOutline className='text-3xl mb-1' />
        </button>
        <div className='flex ml-16 gap-4 mt-8 text-4xl text-primary-500'>
          <a
            href='https://www.instagram.com/henkotsu_udon/'
            aria-label='Instagram of henkotu'
          >
            <CiInstagram />
          </a>
          <a
            href='https://www.facebook.com/HenkotsuUdon/?locale=ja_JP'
            aria-label='facebook of henkotu'
          >
            <CiFacebook />
          </a>
        </div>
      </div>
      <IoMenu
        onClick={() => setIsOpen(true)}
        className={`text-4xl cursor-pointer lg:hidden ${isRoot && !isScrolled && 'text-white'}`}
      />
    </>
  )
}
