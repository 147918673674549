import React from 'react'
import { Link } from 'gatsby'
import { IoBagOutline } from 'react-icons/io5'

export default function DeskMenu({isRoot}: {isRoot: boolean}) {
  return (
    <div className='hidden lg:flex items-center w-full gap-12 xl:gap-16'>
      <ul className={`flex gap-4 ml-auto xl:text-lg xl:gap-8 2xl:text-xl ${isRoot && 'text-white'}`}>
        <li className='hover-underline-animation'><Link to='/'>ホーム</Link></li>
        <li className='hover-underline-animation'><Link to='/about'>へんこつについて</Link></li>
        <li className='hover-underline-animation'><Link to='/menu'>メニュー</Link></li>
        <li className='hover-underline-animation'><Link to='/branches'>店舗一覧</Link></li>
        {/* <li><Link to='/news'>お知らせ</Link></li> */}
        <li className='hover-underline-animation'><Link to='/careers'>採用情報</Link></li>
        <li className='hover-underline-animation'><Link to='/contact'>お問い合せ</Link></li>
      </ul>
      <button
        type='button'
        className={`
        w-36 py-2 flex items-center justify-center gap-2 border rounded ml-auto transition duration-500 ease-in-out hover:bg-primary-500 hover:text-white xl:w-52 xl:py-3 xl:text-lg invisible
        ${isRoot ? 'text-white border-white' : 'text-primary-500 border-primary-500'}
        `}
      >
        テイクアウト <IoBagOutline className='text-3xl mb-1' />
      </button>
    </div>
  )
}